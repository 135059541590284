import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Countdown, { zeroPad } from 'react-countdown';
import AppAppBar from '../components/AppAppBar';
import ThirdwebBtn from '../components/ThirdwebBtn';

import dog1 from '../assets/img/dog_1.png';
import dog2 from '../assets/img/dog_2.png';
import dog4 from '../assets/img/dog_4.png';

import limited from '../assets/img/limited.png';
import partners from '../assets/img/partners.png';
import premium from '../assets/img/premium.png';
import supernova from '../assets/img/supernova.png';
import community from '../assets/img/community-engagement.png';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import XIcon from '@mui/icons-material/X';
import { useActiveWallet } from "thirdweb/react";
import { useActiveWalletChain } from "thirdweb/react";
import { useSwitchActiveWalletChain } from "thirdweb/react";
import { base } from "thirdweb/chains";
import Mint from '../components/Mint';

export default function Home(){
    const account = useActiveWallet();
    const chainId = useActiveWalletChain();
    const switchChain = useSwitchActiveWalletChain();
  
    const renderer = ({ days, hours, minutes, seconds }) => (
        <Grid sx={{width: '80%', paddingTop: '1rem'}} container spacing={1}>
            <Grid item xs={3}>
                <h3 className="roboto-black">{zeroPad(days)}</h3>
                <div className="grayed sale_countdown_t">Days</div>
            </Grid>
            <Grid item xs={3}>
                <h3 className="roboto-black">{zeroPad(hours)}</h3>
                <div className="grayed sale_countdown_t">hours</div>
            </Grid>
            <Grid item xs={3}>
                <h3 className="roboto-black">{zeroPad(minutes)}</h3>
                <div className="grayed sale_countdown_t">mins</div>
            </Grid>
            <Grid item xs={3}>
                <h3 className="roboto-black">{zeroPad(seconds)}</h3>
                <div className="grayed sale_countdown_t">secs</div>
            </Grid>
        </Grid>
    );

    
  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
    }
    };

    return(
        <div className="home_page">
            <AppAppBar />
        
            <Container id="collection" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid className="title_home" item xs={12} sm={12} md={6}>
                        <div>
                            <h1 className="roboto-black">Welcome to the Space Dogs NFT Collection</h1>
                            <p className="grayed pt-2 subtitle roboto-regular">Embark on an interstellar journey with the Spase Dogs NFT Collection, a unique and captivating series of digital art pieces that combine the wonder of space exploration with the charm and loyalty of man's best friend.</p>

                            <div>
                                <Button onClick={() => scrollToSection('buynow')} className="btn_buy" variant="contained">Buy Now</Button>
                            </div>
                        </div>
                    </Grid>
                    <Grid className="grid_rep" item xs={12} sm={12} md={6}>
                        <Grid sx={{overflow: 'hidden'}} container spacing={1}> 
                            <Grid item xs={6}>
                                <Stack spacing={4}>
                                    <div>
                                        <img src={dog1} alt="nft_1" />
                                    </div>
                                    <div>
                                        <img src={dog2} alt="nft_2" />
                                    </div>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="home_nft_3"></div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>

            <Container id="utility" sx={{paddingTop: '10rem',paddingBottom: '10rem'}} maxWidth="lg">
                <h3 className="roboto-black text-center pb-5">Why Choose Base Dogs?</h3>

                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={3}>
                        <div className="box_p">
                            <div className="img_icon_c">
                                <img src={premium} alt="exclusive" />
                            </div>
                            <h6 className="text-center h6_6">Exclusive Designs</h6>
                            <p className="text-justify grayed desc_6"> Every Space Dog in our collection is meticulously crafted by talented artists, ensuring that each piece is a unique work of art. No two Base Dogs are alike, making your NFT truly one-of-a-kind.</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <div className="box_p">
                            <div className="img_icon_c">
                                <img src={partners} alt="community" />
                            </div>
                            <h6 className="text-center h6_6">Community and Utility</h6>
                            <p className="text-justify grayed desc_6"> Owning a Space Dog NFT grants you access to an exclusive community of enthusiasts and collectors. Participate in special events, gain access to future drops, and enjoy the camaraderie of fellow space explorers.</p>
                        </div>
                        
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <div className="box_p">
                            <div className="img_icon_c">
                                <img src={supernova} alt="interstellar" />
                            </div>
                            <h6 className="text-center h6_6">Interstellar Adventures</h6>
                            <p className="text-justify grayed desc_6">Each Space Dog comes with its own cosmic adventures, adding depth and personality to your collection.  </p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <div className="box_p">
                            <div className="img_icon_c">
                                <img src={limited} alt="limited" />
                            </div>
                            <h6 className="text-center h6_6">Limited Edition</h6>
                            <p className="text-justify grayed desc_6">The Space Dogs Collection is strictly limited to just 600 NFTs, ensuring each piece remains rare and valuable.</p>
                        </div>
                    </Grid>
                </Grid>
            </Container>

            <div id="buynow"  className="nft_sale">
                <Container maxWidth="lg">
                    <Grid container spacing={1}>
                        <Grid sx={{position: 'relative'}} className="representationflex" item xs={12} sm={12} md={12}>
                            <div className="container_sale">
                                <h3 className="roboto-black">Collect Yours Now!</h3>
                                <div className="price_sale">
                                    $750 USD <span className="price_eth">0.198 ETH</span>
                                </div>

                                <div>
                                    <div className="grayed sale_countdown_t">
                                        <AccessTimeIcon/> Sale ends in:
                                    </div>
                                    <Countdown date={1718150400000} renderer={renderer} />
                                </div>

                                <div className="pt-3 full-width">
                                    {!account &&
                                        <ThirdwebBtn />
                                    }
                                    {account &&
                                        <>
                                            {chainId.id !== 8453 &&
                                            <Button className="btn_buy" variant="contained" onClick={() => {switchChain(base)}}>Switch Network</Button>}
                                            {chainId.id === 8453 &&
                                                <Mint/>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="nft_representation">
                                <img src={dog4} alt="nft_rep" />
                            </div>
                            
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <Container id="socials" className="socials_cont" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid style={{alignItems: 'center'}} className="title_home" item xs={12} sm={12} md={6}>
                        <div>
                            <h1 className="roboto-black">Stay Connected</h1>
                            <p className="grayed pt-2 subtitle roboto-regular">Follow us on social media and join our Twitter community to stay up-to-date with the latest news, drops, and events. The Base Dogs journey is just beginning, and we want you to be a part of it!</p>

                            <div className="btnflexh">
                                <Button className="btn_buy" onClick={() => window.open('https://x.com/basedogsx', '_blank')} variant="contained"> <XIcon/> Twitter</Button>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Grid sx={{overflow: 'hidden'}} container spacing={1}> 
                            <Grid item xs={12}>
                                <div className="p-5 m-5">
                                    <img className="comm_img" src={community} alt="community" />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>

        </div>
    )
}