/* eslint-disable no-unused-vars */
import * as React from 'react';

import './App.css';
import {Routes, Route} from 'react-router-dom';
import Home from './pages/Home';
import Result from './pages/Result';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import getLPTheme from './getLPTheme';
import CssBaseline from '@mui/material/CssBaseline';
import Footer from './components/Footer';

function App() {
  const [mode, setMode] = React.useState('light');
  const [showCustomTheme, setShowCustomTheme] = React.useState(false);
  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };
  
  return (
    <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
      <CssBaseline />
      <div className="App">
          <Routes>
            <Route path="/:ref_name?/*" element={<Home />} />
            <Route path="/:ref_name?/success" element={<Result />} />
        </Routes>
        <Footer/>
      </div>
    </ThemeProvider>
  );
}

export default App;
