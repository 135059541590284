import Container from '@mui/material/Container';
import { Typography} from '@mui/material';
import { useState } from 'react';

export default function Result(){
    const [obj, setObj] = useState(null);

    return(
        <Container maxWidth="lg">
            <div>
                    <Typography component="h2" variant="h4" textAlign="center" color="text.primary">
                        NFT Minted Successfully!
                    </Typography>
                    {obj &&
                    <>
                        <div className="dis_a">
                            <div className="result_c">
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn_crossmint_end"
                                    href={`https://opensea.io/assets/base/${obj?.contractAddress}/${obj?.tokenIds[0]}`}
                                >
                                    View on OpenSea
                                </a>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn_crossmint_end"
                                    href={`https://basescan.org/tx/${obj?.txId}`}
                                >
                                    View on BaseScan
                                </a>
                            </div>
                        </div>
                    </>
                    }
                    
                            
                </div>
        </Container>
    )
}