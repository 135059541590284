import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import XIcon from '@mui/icons-material/X';
import TelegramIcon from '@mui/icons-material/Telegram';

import logo from "../assets/img/logo-text.png";
import logo_light from "../assets/img/logo-text-light.png";

const logoStyle = {
  width: '30%',
  height: 'auto',
};

function Copyright() {
  return (
    <Typography className="roboto-medium" variant="body2" color="text.primary" mt={1}>
      {'Copyright © '}
      <Link sx={{textDecoration: 'none', color: '#FFC0CB'}}>Base Dogs&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

const scrollToSection = (sectionId) => {
  const sectionElement = document.getElementById(sectionId);
  const offset = 128;
  if (sectionElement) {
    const targetScroll = sectionElement.offsetTop - offset;
    sectionElement.scrollIntoView({ behavior: 'smooth' });
    window.scrollTo({
      top: targetScroll,
      behavior: 'smooth',
    });
  }
};

export default function Footer({mode}) {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            minWidth: { xs: '100%', sm: '60%' },
          }}
        >
            <Box>
              <img
                src={
                  mode === "dark" ? logo : logo_light
                }
                style={logoStyle}
                alt="logo of liberty room"
              />
            </Box>
            <Link sx={{textDecoration: 'none'}} onClick={() => window.open('https://x.com/basedogsx', '_blank')} color="text.secondary" >
                <XIcon className="icon_text"/> Twitter
            </Link>
          </Box>
        
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Link color="text.secondary" onClick={() => {scrollToSection('collection')}}>
            Collection
          </Link>
          <Link color="text.secondary" onClick={() => {scrollToSection('utility')}}>
            Utility
          </Link>
          <Link color="text.secondary" onClick={() => {scrollToSection('buynow')}}>
            Buy Now
          </Link>
          <Link color="text.secondary" onClick={() => {scrollToSection('socials')}}>
            Socials
          </Link>
        </Box>
        
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 4, sm: 8 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <div>
          <Copyright />
        </div>
      </Box>
    </Container>
  );
}
