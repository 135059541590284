import { createThirdwebClient } from "thirdweb";
import {
    ConnectButton,
    lightTheme,
    darkTheme
} from "thirdweb/react";
import {
    createWallet,
    walletConnect,
    inAppWallet,
} from "thirdweb/wallets";
import { base } from "thirdweb/chains";

const client = createThirdwebClient({
    clientId: process.env.REACT_APP_TW_KEY,
});

const wallets = [
    createWallet("io.metamask"),
    createWallet("com.coinbase.wallet"),
    walletConnect(),
    inAppWallet({
        auth: {
        options: [
            "email",
            "google",
            "apple",
            "facebook",
        ],
        },
    }),
    createWallet("com.trustwallet.app"),
];

export default function ThirdwebBtn({mode}) {
    return (
        <ConnectButton
            className="connect_tw"
            client={client}
            wallets={wallets}
            chain={base}
            theme={mode === 'dark' ? 
            darkTheme({colors: { primaryButtonBg: "#FFC0CB" }}) 
            : 
            lightTheme({colors: { primaryButtonBg: "#FFC0CB" }})}
            connectButton={{ label: "Connect" }}
            connectModal={{
                size: "compact",
                title: "Connect",
                showThirdwebBranding: false,
            }}
        />
);
}