import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import { useActiveWallet } from "thirdweb/react";

export default function Mint({ref_name}) {
    const account = useActiveWallet();
    const address = account?.getAccount()?.address;
    const collection_id = "4894807d-dc0c-469c-b4e9-c893137c1340";
    const project_id = "069aad60-f34b-40d0-9f5e-1bec9bd1b11d";
    const price_per_token = 0.198;

    return (
        <CrossmintPayButton
            collectionId={collection_id}
            environment="production"
            checkoutProps={{"paymentMethods":["fiat","ETH"]}}
            projectId={project_id}
            mintTo={address}
            recipient={{
                wallet: address
            }}
            mintConfig={{
                type: 'erc-721',
                totalPrice: (price_per_token*1).toString(),
                quantity: 1,
                _receiver: address,
                _quantity: 1,
                _pricePerToken: price_per_token
            }}
            getButtonText={(connecting, paymentMethod) =>
                connecting ? "Connecting" : `Buy Now`
            }
            successCallbackURL={ref_name ? "https://basedogs.xyz/"+ref_name+"/success" : "https://basedogs.xyz/success"}
        />
    );
}